import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Composable な UI 設計を目指したフロントエンド開発",
  "date": "2018-02-27T08:00:00.000Z",
  "slug": "entry/2018/02/27/170000",
  "tags": ["medley"],
  "hero": "./2018_02_27.png",
  "heroAlt": "composable ui"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは、開発本部の舘野です。医療介護の求人サイト「`}<a parentName="p" {...{
        "href": "https://job-medley.com/"
      }}>{`ジョブメドレー`}</a>{`」の開発を担当しています。`}</p>
    <p>{`昨年、ジョブメドレーでは事業所が利用する採用管理画面の UI リニューアルを行いました。ユーザが使いやすい UI づくりを目指すために、長期間にわたり誰が開発しても一貫性ある UI を実現できるようなシステムが必要です。そこで今回は「Composable」な UI システムの実現をテーマに、どのように開発を行ったのかについて、共有させていただきます。`}</p>
    <h1>{`背景：画面や機能追加のたびに UI の一貫性がなくなっていた`}</h1>
    <p>{`ジョブメドレーの採用管理画面とは、医療機関や介護施設の採用担当者が求人情報の管理や応募者の選考状況の管理などを行う画面です。`}</p>
    <p>{`この採用管理画面ですが、リニューアル以前は`}<a parentName="p" {...{
        "href": "https://angular.io/"
      }}>{`Angular`}</a>{`をフレームワークとして採用した SPA で、UI に関しては`}<a parentName="p" {...{
        "href": "https://angular-ui.github.io/bootstrap/"
      }}>{`AngularUI の Bootstrap`}</a>{`を利用して、それぞれのエンジニアが実装を行っていました。`}</p>
    <p>{`それなりの UI をスピーディーに実現できる点においては、Bootsrap のような UI フレームワークを利用することで受けられる恩恵は大きかったのですが、一方で、包括的に UI 設計を行っているわけではなく、`}<b>{`各人が局所的に UI を作っていくので、画面や機能を追加していく中で一貫性がない UI が増えていく状態`}</b>{`になっていました。`}</p>
    <p>{`実際にユーザインタビューなどを行ってみると、「ログインした後どうすれば良いのか分からない」、「〇〇という機能があることを今まで知らなかった」、「xx がどこにあるのか分からない」などの意見が多々あり、全面的な UI の見直しが必要になっていました。`}</p>
    <p>{`医療や介護の現場での人材不足を解消するために採用担当者に提供するツールとして、今後さらに機能拡充していくことが求められていましたが、機能拡充していくことに耐えうる状態にはないというのがプロダクトチームのメンバーの共通認識でした。`}</p>
    <p>{`そこで、全体的に情報設計から見直してデザインを刷新し、今後プロダクトを成長させていく上でスケール可能な UI を提供できるようにするため、UI リニューアルを決定しました。`}</p>
    <h1>{`フロントエンドで必要だったこと`}</h1>
    <p>{`Bootstrap を用いてエンジニアのみで UI を作っていたのとは異なり、リニューアルでは社内のデザイナーが現状の UI 上の課題を整理したデザインを作成しました。`}</p>
    <p>{`これに伴って、自前で全ての UI パーツを作成することになりましたが、Bootstrap に頼りきっていたときとは違い、堅牢性と柔軟性を伴った UI システムを自分たちで構築する必要がありました。`}</p>
    <p>{`リニューアル前の採用管理画面の UI は一貫性に欠けており、ユーザは非常に多くの操作を学ぶ必要がありましたが、この責任はデザイナーだけでなく UI 開発をするエンジニアにも大いにあります。
良いデザインができても、最終的にプロダクトの UI はコードによって作り上げられるものなので、エンジニア次第で一貫性に欠ける UI になってしまうことは十分にあり得ると思います。`}</p>
    <p>{`往々にして起こり得るのは、目にする機会が比較的少ない画面であったり、改修対象ではない部分などが気づいたら崩れていたり、意図しない UI になってしまっていたりということですが、こういった状況に陥る大きな要因としてはフロントエンドの部分で一貫性に対する配慮ができてないことが 1 つだと思います。`}</p>
    <p>{`そこで、すでにある採用管理画面を使いやすくするのはもちろん、今後スケールしていく中で`}<b>{`一貫性のある UI を担保し続けていくためには、リニューアルでフロントエンドも堅牢で柔軟な UI システムへと変える必要がありました`}</b>{`。`}</p>
    <h1>{`UI リニューアルで開発上大切にしたこと`}</h1>
    <p>{`UI の一貫性を保つとなると、今のフロントエンドではもはや当然のことかもしれませんが、コンポーネント指向で構成することになると思います。`}</p>
    <p>{`技術選定としては、上述の通りリニューアル以前は Angular（v1.4.11）を利用していましたが、リニューアルのタイミングで React へ移行しました。`}</p>
    <p>{`React を選択した理由としては、学習コストの点やコミュニティが活発でエコシステムが充実している点、単一方向のデータフロー、シンプルな API などを総合的に判断してのものですが、目下の課題である UI コンポーネントのメンテナビリティに関しても適切な選択肢であると考えました。`}</p>
    <p>{`CSS の方はというと、リニューアル前は Bootstrap でまかなえない部分は Sass でそれぞれのエンジニアが書きたいように書くという状態でしたが、リニューアルで Sass に加えて一部 PostCSS という構成に変更して、設計は ITCSS、Lint を stylelint で行う、という形にしました。`}</p>
    <p>{`ITCSS を選択した背景としては、その詳細度順のレイヤー階層によってカスケードを管理しやすい点やレイヤーの増減で容易にスケールできる点などから選択しました。`}</p>
    <p>{`CSS in JS も考慮はしましたが、リニューアルの時点ではこれという決定的な選択肢が無かったこともあり（まだ styled-components も正式リリースされてなかった）、`}<a parentName="p" {...{
        "href": "https://github.com/JedWatson/classnames"
      }}>{`classnames`}</a>{`を利用しました。`}</p>
    <p>{`フレームワークやライブラリの選定も重要ですが、UI システムを刷新する上で`}<b>{`開発上最も重視したのは「Composability（コンポーネントの組み合わせが容易であること）」`}</b>{`でした。`}</p>
    <p>{`Composable であるということは、つまり様々な状況において組み込み可能な状態であり、再利用性が高いということになります。
それぞれのコンポーネントを組み合わせることが容易に出来るとともに、複数のコンポーネントを組み合わせた状態から 1 つ 1 つ分解することも容易な状態が望ましく、結果的にそれで UI が構築しやすく改修しやすい状態に自然となるはずです。`}</p>
    <p>{`モーダルを例にあげると、モーダルの中で表示するコンテンツ要素やモーダルの背面に敷くオーバーレイコンポーネントは、モーダルコンポーネント自体には含まず別のコンポーネントとして切り出した方が再利用しやすく、組み合わせやすい、ということです。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "language-html"
      }}><code parentName="pre" {...{
          "className": "language-html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`ModalFrame`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`Modal`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`ModalHead`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`...`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`ModalHead`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`ModalBody`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`...`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`ModalBody`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`Modal`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`Overlay`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`ModalFrame`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p>{`上記の例でいうと、モーダルを画面の中央に配置することは`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<ModalFrame />`}</code>{`が行い、`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<Modal />`}</code>{`自体はモーダルに内包されるコンテンツのコンテナとしての役割だけを持ちます。`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<Overlay />`}</code>{`も独立したコンポーネントの 1 つで、モーダル以外とも組み合わせて利用しています。`}</p>
    <p>{`コンテナとなるコンポーネントとその子となるコンポーネントは、別コンポーネントに分離されていることで、お互いに依存しないようになります。`}</p>
    <p>{`また、Sass ファイルもこのコンポーネント構成に合わせて分けています。
ITCSS において、`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<ModalFrame />`}</code>{`のようなレイアウトのみの役割を持つ場合のスタイルは Objects レイヤー（装飾を持たない UI パターンのレイヤー）となり、装飾を持つ`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<Modal />`}</code>{`や`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<Overlay />`}</code>{`は Components レイヤーとして扱います。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "sass"
    }}><pre parentName="div" {...{
        "className": "language-sass"
      }}><code parentName="pre" {...{
          "className": "language-sass"
        }}><span parentName="code" {...{
            "className": "token atrule-line"
          }}><span parentName="span" {...{
              "className": "token atrule"
            }}>{`@import`}</span>{` “objects.modal-frame”;`}</span>{`
`}<span parentName="code" {...{
            "className": "token atrule-line"
          }}><span parentName="span" {...{
              "className": "token atrule"
            }}>{`@import`}</span>{` “components.modal”;`}</span>{`
`}<span parentName="code" {...{
            "className": "token atrule-line"
          }}><span parentName="span" {...{
              "className": "token atrule"
            }}>{`@import`}</span>{` “components.overlay”;`}</span></code></pre></div>
    <p>{`CSS はその特有のカスケードや詳細度によって決定されるスタイルがあり、依存関係を持たない状態を作ることが困難ですが、ITCSS の考えに則ってそれらの CSS の特徴に逆らわないように詳細度の低いものから順番に`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`@import`}</code>{`するようにしています。`}</p>
    <p>{`Sass ファイルの中身ですが、`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`_objecst.modal-frame.scss`}</code>{`は`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<ModalFrame />`}</code>{`のスタイルのみを記述するようにします。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "sass"
    }}><pre parentName="div" {...{
        "className": "language-sass"
      }}><code parentName="pre" {...{
          "className": "language-sass"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`.o-modal-frame {`}</span>{`
`}<span parentName="code" {...{
            "className": "token property-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`position`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` fixed;`}</span>{`
`}<span parentName="code" {...{
            "className": "token property-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`top`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 0;`}</span>{`
`}<span parentName="code" {...{
            "className": "token property-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`left`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 0;`}</span>{`
`}<span parentName="code" {...{
            "className": "token property-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`right`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 0;`}</span>{`
`}<span parentName="code" {...{
            "className": "token property-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`bottom`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 0;`}</span>{`
`}<span parentName="code" {...{
            "className": "token property-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`z-index`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` map-get(`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$zIndexMap`}</span>{`, modalFrame);`}</span>{`
`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`}`}</span></code></pre></div>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`_components.modal.scss`}</code>{`も同様に`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<Modal />`}</code>{`のスタイルのみを記述します。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "sass"
    }}><pre parentName="div" {...{
        "className": "language-sass"
      }}><code parentName="pre" {...{
          "className": "language-sass"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`.c-modal {`}</span>{`
`}<span parentName="code" {...{
            "className": "token property-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`position`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` relative;`}</span>{`
`}<span parentName="code" {...{
            "className": "token property-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`margin`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 0 auto;`}</span>{`
`}<span parentName="code" {...{
            "className": "token property-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`width`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 900px;`}</span>{`
`}<span parentName="code" {...{
            "className": "token property-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`min-width`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 640px;`}</span>{`
`}<span parentName="code" {...{
            "className": "token property-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`background-color`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$JM-White`}</span>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token property-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`box-shadow`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 0 1px 6px 0 rgba(`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$JM-Black`}</span>{`, 0.2);`}</span>{`
`}<span parentName="code" {...{
            "className": "token property-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`z-index`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` map-get(`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$zIndexMap`}</span>{`, modal);`}</span>{`
`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`}`}</span></code></pre></div>
    <p>{`このように Sass と React コンポーネント毎に 1 対 1 の関係になるようにしています。
プレフィックスとして付与している`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`c-`}</code>{`や`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`o-`}</code>{`は ITCSS のレイヤーのことを指します。
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`o-`}</code>{`は Objects レイヤーのプレフィックスで、`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`c-`}</code>{`は Components レイヤーのプレフィックスです。
基本的に React の UI コンポーネント内では、コンポーネントの種別に応じて`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`c-`}</code>{`か`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`o-`}</code>{`のプレフィックスを持つクラスと、状態によって付けたり外したりする State レイヤーの`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`s-`}</code>{`プレフィックスのクラスのみを使用します。`}</p>
    <p>{`話を React に戻すと、下記のようなヘッダー要素を画面上部に固定表示するだけの役割を持つ`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<AppBar />`}</code>{`コンポーネントは、`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`props.children`}</code>{`で子要素を受け取れるようになっているだけで、その内容には関知しないようになっています。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`AppBar`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`props`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`div className`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"o-app-bar"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`props`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`children`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`div`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`内包する子コンポーネントが何であれ、`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<AppBar />`}</code>{`は自分自身の責任だけを果たせば良いので、開発上もシンプルに考えられます。`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`className`}</code>{`に渡している`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`o-app-bar`}</code>{`は ITCSS の Objects レイヤーのクラスです。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "sass"
    }}><pre parentName="div" {...{
        "className": "language-sass"
      }}><code parentName="pre" {...{
          "className": "language-sass"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`.o-app-bar {`}</span>{`
`}<span parentName="code" {...{
            "className": "token property-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`position`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` fixed;`}</span>{`
`}<span parentName="code" {...{
            "className": "token property-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`top`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 0;`}</span>{`
`}<span parentName="code" {...{
            "className": "token property-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`left`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 0;`}</span>{`
`}<span parentName="code" {...{
            "className": "token property-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`right`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 0;`}</span>{`
`}<span parentName="code" {...{
            "className": "token property-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`z-index`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` map-get(`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$zIndexMap`}</span>{`, appBar);`}</span>{`
`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`}`}</span></code></pre></div>
    <p>{`ヘッダー要素を画面上部に固定表示する、レイアウトのみの役割を持つコンポーネントなので、Objects レイヤーとなり、`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`o-app-bar`}</code>{`にはレイアウト目的のスタイルのみを持たせます。`}</p>
    <p>{`ジョブメドレーの採用管理画面では、医療機関や介護施設から求職者に向けた情報を入力していただくために多くのフォーム要素があり、非常に煩雑になりがちですが、`}<b>{`それぞれの役割を果たすコンポーネントを組み合わせることで、UI 開発上の堅牢性、柔軟性を高められる`}</b>{`ように努めました。`}</p>
    <p>{`実際のリニューアル開発時には、全ての UI コンポーネントを実装する前に、開発側ではデザイナーが用意した Sketch から、全ての UI パーツを洗い出す作業を行い、その中で分解不可能なレベルまでコンポーネントを分解していき、実装すべきコンポーネントを一覧化しました。`}</p>
    <p>{`その後、作成したコンポーネント一覧から全 UI コンポーネントを Storybook に実装していきました。`}</p>
    <p>{`Storybook は、UI コンポーネント開発のサンドボックス環境として、React や Vue を利用した開発では割と一般的に利用されるようになっていると思います。リニューアル時も各コンポーネントの開発環境として利用して、コンポーネントのパターンや組み合わせの確認などを Storybook 上で行いました。`}</p>
    <p>{`画面を作っていく段階では、`}<b>{`用意した UI コンポーネントを組み合わせて利用すれば画面全体の大半の UI が出来上がる`}</b>{`ようになっていました。`}</p>
    <p>{`細かい部分では、事前に用意するコンポーネントに不足があったり、実装した後で仕様の変更によりコンポーネント自体を削除することや、分解不可能な状態まで落とし込めてないコンポーネントが見つかったりと、様々な反省点はありました。ですがリニューアル全体を通して振り返ると、Composable な UI コンポーネントで堅牢で柔軟性のある構成にするということに一定の成果は出せたかなと思います。`}</p>
    <h1>{`まとめ`}</h1>
    <p>{`UI リニューアル以降、採用管理画面ではリニューアル時の UI システムを土台にして、継続的に機能を追加・改修しています。`}</p>
    <p>{`プロダクトで設けている KPI も順調に遷移していて、顧客からの問い合わせもリニューアル以前のような、UI 上の問題で利用が困難であるというものは減少し、ポジティブな結果が得られています。`}</p>
    <p>{`開発をする上でも Composable になるようにコンポーネント群を作成したことで、`}<b>{`リニューアル以降は UI の改修がシンプルに行えるようになり、開発メンバーのスキルセットに左右される部分が少なくなり、開発効率が上がりました`}</b>{`。`}</p>
    <p>{`このような点からリニューアル自体は良かったと思うと同時に、一方でさらに良い UI を提供するために取り組むべきことは、少なくないと感じます。`}</p>
    <p>{`例えば採用管理画面が十分にアクセシブルだとは言えないし、パフォーマンス面でもより一層の努力が求められます。もちろん UI コンポーネントの堅牢性もまだ十分とは言えません。`}</p>
    <p>{`より良いプロダクトを提供するためにそういった課題に対しても継続して取り組んでいきたいと思います。`}</p>
    <h1>{`お知らせ`}</h1>
    <p>{`メドレーでは、エンジニア・デザイナーを募集しています。
メドレーでの開発にご興味ある方は、こちらをご覧ください。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/recruit/creative.html"
      }}>{`https://www.medley.jp/recruit/creative.html`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      